import Button from "@/components/atoms/buttons/Button"
import { openTallyChatbot } from "@/utils/helperFunctions"
import tally from "app/assets/images/tally-logo.webp"
import { memo } from "react"

const TallyBot = () => {
  return (
    <div className="flex flex-col mt-[64px] text-center items-center justify-center text-outer-space">
      <img src={tally} alt="tally bot icon" className="w-[64px]" />
      <p className="mt-[16px] font-bold text-[24px]">Meet Tally, Your AI Copilot</p>
      <div className="mt-[8px] text-[16px] px-10">
        <p className="font-bold">
          Tap into Tally for instant answers to strategy and tech questions — powered by insights from thousands of HR technology profiles.
        </p>
      </div>
      <Button
        variant="teal"
        className="mx-auto mt-[24px] flex h-[48px] w-[206px] text-[18px] items-center justify-center !text-[#031931] py-[4px] sm:text-[1rem] xl:text-[18px] font-medium bg-talivity-teal rounded-[8px]"
        onClick={() => openTallyChatbot()}
      >
        Ask Tally
      </Button>
    </div>
  )
}

export default memo(TallyBot)

import React, { useState, Suspense, useEffect, memo, useCallback } from 'react'
import { footerNavLinks, SocialLinks } from '@/components/atoms/constants/navLinks'
import Button from '@/components/atoms/buttons/Button'
import HybridLink from '@/components/atoms/widgets/HybridLink'
import LeadGenerationModal from '@/components/molecules/modals/LeadGenerationModal'
import useCurrentUser from '@/hooks/useCurrentUser'
import { RiTwitterXLine } from 'react-icons/ri'
import { GrFacebookOption } from 'react-icons/gr'
import { FaLinkedinIn } from 'react-icons/fa'
import TalivityLogo from 'app/assets/images/talivity-logo.webp'
import TalivitySmile from 'app/assets/images/talivity-smile-2.svg'
import { useMobileDetect } from '@/utils/detectMobile'

const FooterSection = () => {
  const currentUser = useCurrentUser()
  const [footerLinks, setFooterLinks] = useState(footerNavLinks)

  useEffect(()=>{
    const linksToFilter = currentUser ? ['Vendor Login'] : []
    if(currentUser?.newsletter_subscribed){
      linksToFilter.push('Sign Up');
    }
    setFooterLinks(footerNavLinks.filter(link => !linksToFilter.includes(link.name)));
  }, [])

  const [openSubscribeModal, setOpenSubscribeModal] = useState(false)
  const handleSignUpTopNav = () => {
    setOpenSubscribeModal(true)
  }

  const formatMobileFooterLinks = useCallback((array, size) => {
    const result = []
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size))
    }
    return result
  }, [])

  const isMobile = useMobileDetect({ screenSize: 550 })

  return (
    <>
      <Suspense fallback={<div></div>}>
        <LeadGenerationModal
          openModal={openSubscribeModal}
          setOpenModal={setOpenSubscribeModal}
          formType={'newsletter'}
          currentUser={currentUser}
        />
      </Suspense>
      <div className='text-center text-black pt-12 bg-cover bg-center bg-gradient-to-b from-white to-[#EAECEE]'>
        {(currentUser?.newsletter_subscribed) ?
          <div className='hidden lg:block absolute right-0 sm:translate-y-[-2rem]'>
            <img src={TalivitySmile} alt='talivity smile logo' className='h-[11rem] sm:h-[20rem] md:h-[25rem] lg:h-[28rem] xl:h-[30rem]' />
          </div>
          :
          <>
            <div>
              <p className='text-[38.78px] 2xl:text-4xl pt-5 sm:pt-14 font-Manrope px-5 font-bold'>Connect with us</p>
              <p className='text-[17px] text-outer-space font-semibold 2xl:text-md px-6 sm:pb-8 sm:pr-6 sm:pl-6 py-4 font-Manrope'>Get weekly insights. Make industry connections. Discover new tech.</p>
              <Button
                variant='teal'
                className='mx-auto flex h-[57.2px] w-[212.2px] text-[14.91px] items-center justify-center !text-[#031931] py-[4px] sm:text-xs md:text-[16px] lg:text-[18px] font-bold bg-talivity-teal rounded-[10.5px]'
                onClick={handleSignUpTopNav}
              >
                Sign Up
              </Button>
            </div>
            <div className='hidden lg:block absolute right-0 sm:translate-y-[-2rem] md:translate-y-[-6rem] lg:translate-y-[-10rem]'>
              <img src={TalivitySmile} alt='talivity smile logo' className='h-[11rem] sm:h-[20rem] md:h-[25rem] lg:h-[28rem] xl:h-[30rem]' />
            </div>
          </>
        }
        <div className='sm:pt-[6rem] pt-14'>
          <p className='font-semibold text-[23.78px] 2xl:text-2xl xl:text-[23.78px] font-Manrope'>Follow us</p>
          <div className='flex justify-center items-center sm:gap-8 gap-6 pt-6'>
            <div className='flex items-center justify-center border-2 border-black sm:p-3 rounded-full w-[77px] h-[77px] sm:w-[4.6875rem] sm:h-[4.6875rem] '>
              <HybridLink
                href={SocialLinks.linkedin.url}
                isExternal={SocialLinks.linkedin.external}
              >
                <FaLinkedinIn className='w-[33px] h-[33px] sm:w-[40px] sm:h-[35px]' />
              </HybridLink>
            </div>
            <div className='flex items-center justify-center border-2 border-black sm:p-3 rounded-full w-[77px] h-[77px] sm:w-[4.6875rem] sm:h-[4.6875rem] stroke-6'>
              <HybridLink
                href={SocialLinks.facebook.url}
                isExternal={SocialLinks.facebook.external}
              >
                <GrFacebookOption  className='w-[33px] h-[33px]  sm:w-[40px] sm:h-[40px]' />
              </HybridLink>
            </div>
            <div className='flex items-center justify-center border-2 border-black sm:p-3 rounded-full w-[77px] h-[77px] sm:w-[4.6875rem] sm:h-[4.6875rem] stroke-6'>
              <HybridLink
                href={SocialLinks.twitter.url}
                isExternal={SocialLinks.twitter.external}
              >
                <RiTwitterXLine className='w-[33px] h-[33px] sm:w-[40px] sm:h-[40px]'/>
              </HybridLink>
            </div>
          </div>
        </div>
        <div className='sm:pt-[133.93px] pt-12'>
          <img src={TalivityLogo} alt='talivity logo' className='h-[100.973px] w-[126.6px] mx-auto pb-[30.07px]'/>
          {isMobile ? (
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-2 md:gap-4 pb-8 md:pb-[42.07px] px-5'>
              {formatMobileFooterLinks(footerLinks, 3).map((chunk, chunkIndex) => (
                <div key={chunkIndex} className='flex items-center justify-center'>
                  {chunk.map((footerLink, index) => (
                    <React.Fragment key={index}>
                      {footerLink.name === 'Sign Up' ?
                        <span onClick={() => setOpenSubscribeModal(true)} className='2xl:text-lg text-[16px] sm:text-[16px] cursor-pointer'>
                          {footerLink.name}
                        </span> :
                        <HybridLink
                          href={footerLink.url}
                          isExternal={footerLink.external}
                          className='2xl:text-lg text-[16px] sm:text-xs xl:text-[16px] lg:text-[16px]'
                        >
                          {footerLink.name}
                        </HybridLink>
                      }
                      {index < chunk.length - 1 && (
                        <span className='2xl:text-xl md:text-sm text-[18px] mx-[16px] sm:mx-[8px]'>
                          {'|'}
                        </span>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              ))}
            </div>
          ) :
            <div className='flex flex-wrap justify-center md:gap-4 sm:gap-2 gap-1 md:pb-[35.07px] pb-2 px-6'>
              {
                footerLinks.map((footerLink, index) => (
                  <>
                    {
                      footerLink.name === 'Sign Up' ?
                        <span onClick={() => setOpenSubscribeModal(true)}
                          className='2xl:text-lg text-[16px] xl:text-[16px] md:text-[16px] cursor-pointer font-medium' key={index}>{footerLink.name}</span> :
                        <HybridLink
                          key={index}
                          href={footerLink.url}
                          isExternal={footerLink.external}
                          className='2xl:text-lg text-[16px] sm:text-[16px] md:text-[16px] font-medium'
                        >
                          {footerLink.name}
                        </HybridLink>
                    }
                    <div className='sm:text-[19px] lg:text-[21px] sm:translate-y-[-1px]  mx-2'>
                  {index === footerLinks.length-1 ? null : '|' }
                    </div>
                  </>
                ))
              }
            </div>
          }
          <p className='text-[14px] font-Manrope 2xl:text-md px-7 pt-3 font-medium pb-20'>© Copyright 2025 Talivity, Inc • All Rights Reserved •&nbsp;
            <a href='https://www.talivity.com/privacy-policy/' className='no-underline' target='_blank'>
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </>
  )
}

export default memo(FooterSection)

import { useRef, useState } from 'react'
import axios from 'axios'
import { useToken } from '@/components/atoms/forms/Form'
import { router } from '@inertiajs/react'
import { toast } from 'react-toastify'
import { FaGears } from "react-icons/fa6"
import { MdOutlineCreate } from "react-icons/md"
import { LiaTrashAltSolid } from "react-icons/lia"
import { FaCirclePlus } from "react-icons/fa6"
import { GoDotFill } from "react-icons/go"
import { RxCross1 } from "react-icons/rx"
import { CiSearch } from "react-icons/ci"
import Input from '@/components/atoms/inputs/Input'
import Button from '@/components/atoms/buttons/Button'
import { searchStringArray } from '@/utils/searchStringArray'
import SuccessMessage from '@/components/atoms/Toast/SuccessMessage'
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage'
import window from 'global'
import { NUM_OF_LIST_ELEMENTS_LARGE_SCREEN, NUM_OF_LIST_ELEMENTS_SMALL_SCREEN, WIDTH_SMALL_SCREEN } from '@/components/atoms/constants/screenConstant'
import { defaultToastOptions } from '@/utils/commonToast'
import EmptySection from '@/components/atoms/widgets/EmptySection'

export const Integration = ({integrations, solutionSlug, isSolutionOwner, isRmcAdmin}) => {

  const TIMEOUT_INTERVAL = 1000
  const numOfListElements = useRef(window.innerWidth > WIDTH_SMALL_SCREEN ? NUM_OF_LIST_ELEMENTS_LARGE_SCREEN : NUM_OF_LIST_ELEMENTS_SMALL_SCREEN)

  const [editView, setEditView] = useState(false)
  const [integrationsToShow, setIntegrationsToShow] = useState(integrations.slice(0, numOfListElements.current))
  const [input, setInput] = useState('')
  const [expandList, setExpandList]  = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const token = useToken()
  const showEmptySection = (!editView && integrations.length === 0 && (isRmcAdmin || isSolutionOwner))

  const handleSubmit = async () => {
    try{
      const solutionUpdateURL =  `/solutions/${solutionSlug}`
      const response = await axios.put(
        `/solutions/${solutionSlug}`,
        {
          solution: {
            integrations: integrationsToShow
          }
        },
        {
          headers: {
            'X-CSRF-Token': token,
            Accept: 'application/json',
          },
        }
      )
      if(response.status == 200 )
      {
        toast(
          <SuccessMessage message={'Your changes have been saved successfully!'}/>,
          defaultToastOptions('teal-tint-2')
        )
        setTimeout(()=>router.visit(solutionUpdateURL), TIMEOUT_INTERVAL)
      }
    }
    catch (error) {
      toast(
        <ErrorMessage message={'Something went wrong! Please try again.'}/>,
        defaultToastOptions('red-200')
      )
    }
  }

  const deleteIntegration = (integrationToDelete) => {
    setIntegrationsToShow(
      integrationsToShow.filter((integration) => integration !== integrationToDelete)
    )
  }

  const processInput = () => {
    const newIntegrations = input.split(',').map(str => str.trim()).filter(str => str !== '')
    const uniqueIntegrations = Array.from(new Set([...integrationsToShow, ...newIntegrations]))
    setIntegrationsToShow(uniqueIntegrations)
    setInput('')
  }

  const handleSearch = (e) => {
    const inputValue = e.target.value.trim()
    if (inputValue === '') {
        setIntegrationsToShow(integrations.slice(0, numOfListElements.current))
    } else {
        setIntegrationsToShow(searchStringArray(inputValue, integrationsToShow))
    }
  }

  const closeEditView = () => {
    setEditView(false)
    setIntegrationsToShow(integrations.slice(0, numOfListElements.current))
  }

  return (
    <div className='flex flex-col gap-4 mt-20'>
      <div className='flex justify-between'>
        <div className='flex gap-2 items-center'>
          <FaGears className='text-[30px]'/>
          <h2 className='text-md sm:text-[21.6px] font-semibold'>Product Integrations</h2>
        </div>
        {(isSolutionOwner || isRmcAdmin) && !editView && <MdOutlineCreate className='bg-light-gray p-2 rounded-full text-[35px] cursor-pointer' onClick={() => setEditView(true)}/>}
        {editView &&
        <div className='flex gap-2'>
          <Button variant='teal' className="flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3" onClick={() => handleSubmit()}>Save</Button>
          <Button variant='gray' className="flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3" onClick={() => closeEditView()}>Cancel</Button>
        </div>
        }
      </div>
      {showEmptySection ?
        <EmptySection heading='No Integrations Yet' paragraph='Click the pencil icon to add integrations to your profile.'/> :
        <div className='flex flex-col gap-5'>
          {editView?
            <div className='flex flex-col sm:flex-row w-full gap-3 items-center'>
              <div className='flex items-center gap-3 w-full sm:w-auto'>
                <Input 
                  className='relative sm:!w-[290px] !rounded-full text-xs !border-[#D9D9D9] !border-1 h-[28px]' 
                  onChange={(e)=>setInput(e.target.value)} 
                  placeholder='Add comma separated list of integrations' 
                  aria-label='integrations'
                  value={input}
                />
                <FaCirclePlus  className='text-[28px] cursor-pointer' onClick={() => processInput()}/>
              </div>
              <p className='text-xs'>
                Enter the name for a product / platform that your product currently integrates with and then press the add button. You can use a comma to enter more than one name at a time (name1, name2, name3, etc...)
              </p>
            </div> :
            <div className='flex justify-between items-center px-2 sm:w-min rounded-full border border-1 border-[#D9D9D9]'>
              <div className='flex gap-1 items-center p-[1px]'>
                <CiSearch />
                <input
                  className='w-full sm:w-[290px] text-xs  h-[28px] border-none outline-none focus:ring-0'
                  placeholder='Search Integrations'
                  aria-label='Search integrations'
                  onChange={(e)=>{
                    setSearchTerm(e.target.value)
                    handleSearch(e)
                  }}
                  value={searchTerm}
                />
              </div>
              {searchTerm && <RxCross1 className="cursor-pointer" onClick={() => {
                setSearchTerm('')
                setIntegrationsToShow(integrations)
              }}/>}
            </div>
          }
          <div className='flex flex-col sm:grid sm:grid-cols-3 justify-start gap-4'>
            {
              integrationsToShow?.map((integration, key) => {
                return(
                  <div className={`flex gap-2 items-center ${editView && 'hover:shadow hover:shadow-xs'}`}>
                    {editView && <GoDotFill className='text-xs'/>}
                    <div key={key} className='flex justify-between items-center gap-2 w-full'>
                      <p className='text-sm'>{integration}</p>
                      {editView && <LiaTrashAltSolid className='cursor-pointer' onClick={()=>deleteIntegration(integration)}/>}
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className='flex justify-between text-xs text-[#808080]'>
            <p>Displaying {integrationsToShow.length} of {integrationsToShow.length > integrations.length ? integrationsToShow.length : integrations.length}</p>
            {integrations.length > numOfListElements.current && <p className='cursor-pointer' onClick={() => {
              const isExpandedView = !expandList
              setExpandList(!expandList)
              setIntegrationsToShow(isExpandedView ? integrations : integrationsToShow.slice(0, numOfListElements.current))
              }}>{expandList ? 'Collapse List': 'Expand List'}</p>}
          </div>
        </div>
      }
    </div>
  )
}

import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { RxCross2 } from "react-icons/rx"
import ErrorMessage from "@/components/atoms/Toast/ErrorMessage"
import { defaultToastOptions } from "@/utils/commonToast"
import { IoCheckmarkCircle } from "react-icons/io5"
import { IoIosArrowDown } from "react-icons/io"

export const AdditionalFeature = ({
  additional_features,
  data,
  setData,
  editView,
  numOfListElements,
  additionalFeaturesToShow,
  setAdditionalFeaturesToShow,
}) => {
  const [input, setInput] = useState("");
  const [toggleFeatures, setToggleFeatures] = useState(true)
  const MAX_CHARACTER_COUNT = 55
  
  useEffect(() => {
    setAdditionalFeaturesToShow(
      additional_features.slice(0, numOfListElements.current)
    )
  }, [additional_features])

  const deleteAdditionalFeature = (featureName) => {
    setData(
      "feature_solutions_attributes",
      data.feature_solutions_attributes.map((feature) => {
        if (feature.feature_attributes.name === featureName) {
          if (feature.feature_attributes.source === "Solution") {
            return {
              ...feature,
              _destroy: true,
              feature_attributes: {
                ...feature.feature_attributes,
                _destroy: true,
              },
            };
          } else {
            return { ...feature, _destroy: true };
          }
        } else {
          return feature;
        }
      })
    )
    setAdditionalFeaturesToShow(
      additionalFeaturesToShow.filter(
        (feature) => feature.name !== featureName
      )
    )
  }

  const handleViewFeatures = () => {
    setToggleFeatures(!toggleFeatures)
    setAdditionalFeaturesToShow(
      toggleFeatures ? additional_features : additional_features.slice(0, numOfListElements.current)
    )
  }
  
  const processInput = () => {
    let characterCountIncreased = false;
    if (input.trim() === "") return;
    const newAdditionalFeatures = input
      .split(",")
      .map((str) => str.trim())
      .filter((str) => str !== "");

    newAdditionalFeatures.every((feature) => {
      if (feature.length > MAX_CHARACTER_COUNT) {
        characterCountIncreased = true
        return false;
      }
    })

    if (characterCountIncreased) {
      toast(
        <ErrorMessage message={"Feature names should not exceed 55 characters."} />,
        defaultToastOptions("red-200")
      );
      return;
    } 

    const uniqueAdditionalFeatures = Array.from(
      new Set([
        ...additionalFeaturesToShow.map((feature) => feature.name),
        ...newAdditionalFeatures,
      ])
    )

    if (uniqueAdditionalFeatures.length === additionalFeaturesToShow.length) {
      toast(
        <ErrorMessage message={"Duplicate Features are not allowed."} />,
        defaultToastOptions("red-200")
      );
      return;
    }
    setData("feature_solutions_attributes", [
      ...data.feature_solutions_attributes,
      ...newAdditionalFeatures.map((feature) => ({
        feature_attributes: { name: feature },
        _destroy: false,
      })),
    ])
    setAdditionalFeaturesToShow(
      uniqueAdditionalFeatures.map((name) => ({ name }))
    );
    setInput("");
  }

  return (
    <div className="flex flex-col gap-3 sm:mt-10 mt-6">
      {
        (additionalFeaturesToShow.length > 0 || editView) &&
        <div className="flex justify-between">
          <div className="flex gap-2 items-center">
            <p className='font-semibold text-black'>
              Additional Features
            </p>
          </div>
          {additional_features.length > numOfListElements.current && (
            <button className="flex flex-row items-center gap-2" onClick={handleViewFeatures}>
              <p className='font-semibold text-black'>{toggleFeatures? 'View All' : 'View Less'}</p>
              <IoIosArrowDown
                  className={`transform transition-transform duration-300 ${!toggleFeatures ? 'rotate-180' : ''}`}
                  aria-hidden='true'
              />
            </button>
          )}
        </div>
      }
      <div className="flex flex-col gap-5">
        {editView && (
          <div>
            <div className="flex flex-col sm:flex-row w-full gap-3 items-center">
              <div className="flex items-center gap-0 w-full sm:w-auto">
                <input
                  className="relative sm:!w-[290px] !rounded-tl-md !rounded-bl-md text-xs !border-[#D9D9D9] !border-1 h-[28px]"
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Add additional feature(s)"
                  value={input}
                />
                <button
                  className="px-4 h-[28px] !rounded-tr-md !rounded-br-md bg-dark-blue hover:bg-dark-blue-hover active:bg-dark-blue-clicked text-white"
                  onClick={processInput}
                >
                  Add
                </button>
              </div>
            </div>
            <p className="text-[11px] mt-1">
              Add multiple features by separating with commas
            </p>
          </div>
        )}
        <div className="flex flex-col grid grid-cols-1 gap-0.5 md:grid-cols-3 md:gap-x-5 xl:grid-cols-4">
          {additionalFeaturesToShow.map((feature) => (
            <div key={feature.id} className="flex gap-2 items-center">
              <div className="flex items-center gap-2 w-full">
                {editView && (
                  <RxCross2
                    className="cursor-pointer text-red-600 font-semibold text-[18px] flex-shrink-0"
                    onClick={() =>
                      deleteAdditionalFeature(feature.name)
                    }
                  />
                )}
                {!editView && (
                  <IoCheckmarkCircle size={18} className='text-emerald flex-shrink-0' aria-hidden='true' />
                )}
                <p className="text-sm whitespace-wrap">{feature.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
import React, { useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

type ReCaptchaProps = {
  setRecaptchaToken: (value: string) => void
}

const ReCaptcha: ReCaptchaProps = ({ setRecaptchaToken }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/api.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const handleVerify = (response) => {
    if (response) {
      setRecaptchaToken(response)
    }
  }

  return (
    <div>
      <ReCAPTCHA
        sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
        onChange={handleVerify}
      />
    </div>
  )
}

export default ReCaptcha

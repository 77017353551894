import PromotionFormSection from '../../molecules/promotion/PermotionFormSection'

type SolutionShowProps = {
  solution: schema.Solution;
  promotions: schema.Promotion[];
};

export default function PromotionForm({ ...props }: SolutionShowProps) {
  const { solution, promotions } = props;

  return (
    <div>
      <PromotionFormSection
        solution={solution}
        promotions={promotions}
      />
    </div>
  );
}

import { IoCheckmarkCircle } from 'react-icons/io5'

export function CategoryFeatures({ categories_master_features }) {
  return (
    <div className='flex flex-col gap-5'>
      {categories_master_features.map((categories_master_feature, index) => (
        categories_master_feature.selected_master_features.length > 0 &&
        categories_master_feature.master_features.length > 0 && (
          <div className={`flex flex-col gap-3`} key={index}>
            <p className='font-semibold text-black'>
              {categories_master_feature.category}
            </p>
            <ul
              className={
                'grid grid-cols-1 gap-0.5 md:grid-cols-3 md:gap-x-5 xl:grid-cols-4'
              }
            >
              {categories_master_feature.master_features.map((master_feature) => {
                const isSelected =
                  categories_master_feature.selected_master_features.includes(
                    master_feature.name
                  )
                return (
                  isSelected && (
                    <li className='flex h-min items-center gap-1.5 text-sm text-black'>
                      <IoCheckmarkCircle size={18} className='text-emerald' />
                      <label className={isSelected && 'text-black'}>{master_feature.name}</label>
                    </li>
                  )
                )
              })}
            </ul>
          </div>
        )
      ))}
    </div>
  )
}

import { useState, useEffect } from 'react'

export function EditCategoryFeatures({
  categories_master_features,
  seSelectedFeatures,
}) {
  const initialSelectedMasterFeatures = categories_master_features.flatMap(
    (category) =>
      category.selected_master_features.map((feature) => ({
        name: feature,
        category: category.category,
      }))
  )

  const [selectedMasterFeatures, setSelectedMasterFeatures] = useState(
    initialSelectedMasterFeatures
  )

  const isFeatureSelected = (feature, category) => {
    return selectedMasterFeatures.some(
      (item) => item.name === feature.name && item.category === category
    )
  }

  const handleOnChange = (master_feature, category) => {
    setSelectedMasterFeatures((prevSelected) => {
      const isSelected = prevSelected.some(
        (item) =>
          item.name === master_feature.name && item.category === category
      )
      if (isSelected) {
        return prevSelected.filter(
          (item) =>
            !(item.name === master_feature.name && item.category === category)
        )
      } else {
        return [...prevSelected, { name: master_feature.name, category }]
      }
    })
  }

  useEffect(() => {
    seSelectedFeatures(selectedMasterFeatures)
  }, [selectedMasterFeatures])

  return (
    <div className='flex flex-col gap-5'>
      {categories_master_features.map(
        (categories_master_feature, categoryIndex) => (
          categories_master_feature.master_features.length > 0 && (
            <div className='flex flex-col gap-3' key={categoryIndex}>
              <p className='font-semibold text-black'>
                {categories_master_feature.category}
              </p>
              <ul className='grid grid-cols-1 gap-0.5 md:grid-cols-3 md:gap-x-5 xl:grid-cols-4'>
                {categories_master_feature.master_features.map(
                  (master_feature, featureIndex) => {
                    const isSelected = isFeatureSelected(
                      master_feature,
                      categories_master_feature.category
                    )
                    return (
                      <div
                        className='flex h-min items-center gap-1.5 text-sm text-black'
                        key={featureIndex}
                      >
                        <input
                          type='checkbox'
                          name={master_feature.name}
                          value={master_feature.name}
                          checked={isSelected}
                          aria-checked={isSelected}
                          onChange={() =>
                            handleOnChange(
                              master_feature,
                              categories_master_feature.category
                            )
                          }
                          style={{
                            appearance: 'none',
                            width: '15px',
                            height: '15px',
                            border: isSelected ? 'none' : '1px solid #979797',
                            borderRadius: '4px',
                            position: 'relative',
                            cursor: 'pointer',
                            outline: 'none',
                            color: '#00A3FF',
                          }}
                        />
                        <label className={isSelected? 'text-black' : 'text-dusty-gray'}>{master_feature.name}</label>
                      </div>
                    )
                  }
                )}
              </ul>
            </div>
          )
        )
      )}
    </div>
  )
}

import promoImage from '../../../../assets/images/promo-image.jpeg'
const FastTrackPromo = () => {
  return (
    <div
      className='rounded-[0.75rem] shadow-card  overflow-hidden h-auto flex flex-col justify-between pb-4 items-center self-stretch'
      style={{
        background: `linear-gradient(0deg, rgba(3, 25, 49, 0.64) 0%, rgba(3, 25, 49, 0.64) 100%), linear-gradient(0deg, rgba(3, 25, 49, 0.64) 0%, rgba(3, 25, 49, 0.64) 100%), url(${promoImage})`,
        backgroundBlendMode: 'normal, multiply, normal',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className='flex flex-col items-center justify-center text-center p-4 gap-2'>
        <h2 className='text-white text-2xl font-semibold mb-2'>
          Build Your Dream HR Tech Stack with FastTrack
        </h2>
        <p className='text-gray-300 text-sm mb-4'>
          In less than 10 minutes, answer a few questions and receive
          personalized HR tech stack recommendations from across the market.
        </p>
      </div>
      <div className='flex justify-center items-center'>
        <button className='bg-pink-tint-3 text-white font-medium text-[15px] leading-[26px] tracking-[0.4px] py-[0.5rem] px-[1rem] flex justify-center items-center gap-2 rounded-lg hover:bg-pink-600 mx-4 mb-4'>
          Get Matched Now
        </button>
      </div>
    </div>
  )
}

export default FastTrackPromo
import { useEffect, useState, lazy } from 'react'

import Layout from '@components/organisms/layouts/Layout'
const UnauthorizedClaimModal = lazy(() => import('@components/organisms/modals/UnauthorizedClaimModal'))
import TestimonialsSection from '@components/organisms/TestimonialsSection/TestimonialsSection'
import { Link, useForm } from '@inertiajs/react'
import axios from 'axios'
import clsx from 'clsx'
import { FaShuffle } from "react-icons/fa6"
import { ToastContainer, toast } from 'react-toastify'
import { useTracking } from 'react-tracking'

import closeIcon from '../../../assets/images/close-icon-black.svg'
import companySizeIcon from '../../../assets/images/companySizeIcon.svg'
import editIcon from '../../../assets/images/edit-pencil.svg'
import industryIcon from '../../../assets/images/industryIcon.svg'
import pocketshadow from '../../../assets/images/pocketshadow.svg'

import 'react-toastify/dist/ReactToastify.css'
import Button from '@/components/atoms/buttons/Button'
import { useToken } from '@/components/atoms/forms/Form'
import Modal from '@/components/atoms/modals/Modal'
import { RmcNewsAndMedia } from '@/components/atoms/newsAndMedia/RmcNewsAndMedia'
import AwardsSection from '@/components/atoms/rmcAward/AwardsSection'
import SolutionFeatures from '@/components/atoms/solution/SolutionFeatures'
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage'
import SuccessMessage from '@/components/atoms/Toast/SuccessMessage'
import EmptySection from '@/components/atoms/widgets/EmptySection'
import Meta from '@/components/atoms/widgets/Meta'
import PageTitle from '@/components/atoms/widgets/PageTitle'
import SimilarSolutionCard from '@/components/molecules/categoryPage/SimilarSolutionCard'
import CompareSolutionTab from '@/components/molecules/compareSolution/CompareSolutionTab'
import HeroImageSection from '@/components/molecules/heroSectionOfSolution/HeroImageSection'
import { Integration } from '@/components/molecules/integrationSection/Integration'
import { StartGeneratingQualityLeadsCTA } from '@/components/molecules/leads/StartGeneratingQualityLeadsCTA'
import ImagePreviewModal from '@/components/molecules/modals/ImagePreviewModal'
import LeadGenerationModal from '@/components/molecules/modals/LeadGenerationModal'
import OfficialMediaSection from '@/components/molecules/officialMediaSection/OfficialMediaSection'
import OurCustomersSection from '@/components/molecules/ourCustomers/OurCustomersSection'
import PromotionCard from '@/components/molecules/promotion/PromotionCard'
import { Propositions } from '@/components/molecules/propositions/Propositions'
import Breadcrumbs from '@/components/molecules/solution/Breadcrumbs'
import ProfileHeader from '@/components/molecules/solution/profileHeader'
import SolutionDetails from '@/components/molecules/solutionDetails/solutionDetails'
const FaqSection = lazy(() => import('@/components/organisms/FaqSection/FaqSection'))
import PromotionForm from '@/components/organisms/forms/PromotionForm'
import PricingDetails from '@/components/organisms/solution/pricingDetails'
import useComparedSolutions from '@/hooks/useComparedSolutions'
import useZoomInfoScript from '@/hooks/useZoomInfoScript'
import { putRequest } from '@/utils/axiosRequests'
import { defaultToastOptions } from '@/utils/commonToast'
import { useMobileDetect } from '@/utils/detectMobile'
import { getCategoryUrl } from '@/utils/helperFunctions'
import PromoFastTrack from '@/components/atoms/solutionProfile/PromoFastTrack'

type SolutionShowProps = {
  solution: schema.Solution
  current_user?: schema.User
  categories: schema.Category[]
  company: schema.Company
  is_claimed: boolean
  claimed_by: schema.User
  solutionOwner: schema.User
  similar_solutions: schema.Solution[]
  logo_url: string
  rmc_awards?: schema.RmcAward[]
  solution_suites: schema.Solution[]
  rmc_news_and_media: schema.Link[]
  promotions: schema.Promotion[]
  social_links: schema.SocialLink[]
  documents: schema.Document[]
  screenshots: schema.Screenshot[]
  videos: schema.Video[]
  heroImageURL?: string
  customers: schema.Customer[]
  integrations: string[]
  additional_features: schema.Feature[]
  propositions: schema.Proposition[]
  customSolutionCta: schema.CustomSolutionCta
  about_media: {
    media_url: string
    media_type: string
    file_name: string
  }
  categories_master_features: [{
    category: schema.Category
    master_features: schema.MasterFeature[]
    selected_master_features: schema.MasterFeature[]
  }]
  role: string
  primary_category?: schema.Category
  faqs: schema.Faqs[]
  testimonials: schema.Testimonials[]
}

const SOLUTION_CARD_WIDTH = 401
const MAX_HERO_IMAGE_SIZE = 5 * 1024 * 1024
const SOLUTION_SLIDE = 1

export default function SolutionShow({ ...props }: SolutionShowProps) {
  const {
    solution,
    company,
    current_user,
    is_claimed,
    solutionOwner,
    similar_solutions = [],
    logo_url,
    rmc_awards,
    solution_suites = [],
    rmc_news_and_media = [],
    promotions,
    social_links = [],
    documents = [],
    videos = [],
    logo,
    screenshots,
    heroImageURL,
    mobileHeroImageURL,
    customers = [],
    integrations,
    additional_features,
    propositions,
    customSolutionCta,
    about_media = {},
    role,
    primary_category,
    categories_master_features={},
    faqs=[],
    testimonials=[],
  } = props

  if(solution?.name === 'Recruitics') useZoomInfoScript()

  const [openUnauthorizedFormModal, setOpenUnauthorizedFormModal] =
    useState(false)
  const [isClaim, setIsClaim] = useState(false)
  const [openClaimModal, setOpenClaimModal] = useState(false)
  const [contactUsBtn, setContactUsBtn] = useState(false)
  const [status, setStatus] = useState('initial')
  const [showRequestAQuote, setShowRequestAQuote] = useState(false)
  const [showPermotionForm, setShowPermotionForm] = useState(false)
  const [heroPreviewUrl, setHeroPreviewUrl] = useState(null)
  const [mobileHeroPreviewUrl, setMobileHeroPreviewUrl] = useState(null)
  const [modals, setModals] = useState({IndustriesModal: false, CompanySizeModal: false })
  const [hideNav, setHideNav] = useState(false)
  const [hideDropdown, setHideDropdown] = useState(false)
  const [openLeadGenModal, setOpenLeadGenModal] = useState(false)
  const [cropImage, setCropImage] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [leadSourceBtn, setLeadSourceBtn] = useState('')
  const [solutionsToCompare, setSolutionsToCompare] = useComparedSolutions()

  const isMobile = useMobileDetect({ screenSize: 570 })
  const [selectedInput, setSelectedInput] = useState<'desktop' | 'mobile'>('desktop')

  const token = useToken()

  const tracking = useTracking()

  const isSolutionOwner = current_user ? current_user.id === solutionOwner?.id : false
  const isRmcAdmin = current_user && role === 'rmc_admin'
  const showFaq = isSolutionOwner  || isRmcAdmin
  const handleModals = (event, modalName, value) => {
    event.preventDefault()
    setModals({ ...modals, [modalName]: value })
  }
  
  const reverseCompanySizeMap = {
    self_employed: 'self-employed',
    ten: '1-10 employees',
    fifty: '11-50 employees',
    two_hundred: '51-200 employees',
    five_hundred: '201-500 employees',
    one_thousand: '501-1,000 employees',
    five_thousand: '1,001-5,000 employees',
    ten_thousand: '5,001-10,000 employees',
    max: '10,001+ employees',
  }

  const primaryCategoryBreadCrumbs = [
    { name: 'Marketplace', url: '/marketplace' },
    { name: `${primary_category?.name}`, url: `/categories/${primary_category?.slug}` }
  ]

  const breadcrumbs = [
    { name: 'Marketplace', url: '/marketplace'}
  ]

  useEffect(() => {
    const sliderData = [
      {
        slideState: SOLUTION_SLIDE,

        sliderId: '#solutions-slider',
        slideData: similar_solutions,
      },
      {
        slideState: SOLUTION_SLIDE,
        
        sliderId: '#solutions-suite-slider',
        slideData: solution_suites,
      },
    ]
    sliderData.forEach((slider) => {
      const solutionSlider = document.querySelector(slider.sliderId)
      const clampedSlide = Math.max(
        Math.min(slider.slideData.length + 1, slider.slideState),
        1
      )

      if (solutionSlider) {
        solutionSlider.scroll({
          // Slides start at 0.
          left: (clampedSlide - 1) * SOLUTION_CARD_WIDTH,
          behavior: 'smooth',
        })
      }
    })
  }, [SOLUTION_SLIDE, similar_solutions, solution_suites])

  const handleRequestAQuote = async () => {
    setOpenLeadGenModal(true)
    setLeadSourceBtn('request_a_quote')
  }

  const handleContactUs = async () => {
    setOpenLeadGenModal(true)
    setLeadSourceBtn('contact_us')
  }

  const handlePermotionEditClick = () => {
    setShowPermotionForm(true)
  }

  const handleClaim = async () => {
    const response = await axios.post(
      '/claims',
      {
        claim: {
          solution_id: solution.id,
          user_id: current_user?.id,
        },
      },
      {
        headers: {
          'X-CSRF-Token': token,
        },
      }
    );
    if (response.status === 200) {
      tracking.trackEvent({
        event_name: 'claim_solution_form_submit',
        page_url: window.location.pathname,
        event_loggable_type: "RmcEvents::RmcEvent",
        additional_attributes: { event_type: 'cta_submit' }
      })
      toast(
        <SuccessMessage message={`Your claim for ${solution.name} has been submitted for verification.`} />,
        defaultToastOptions('teal-tint-2')
      );
      setOpenClaimModal(false);
    }
  }

  const handleSubmitClaim = () => {
    if (current_user) {
      setOpenClaimModal(true)
    } else {
      setIsClaim(true)
      setOpenUnauthorizedFormModal(true)
    }
    tracking.trackEvent({
      event_name: 'claim_this_solution',
      page_url: window.location.pathname,
      event_loggable_type: "RmcEvents::RmcEvent",
      additional_attributes: { event_type: 'click_event' }
    })
  }

  const industries = solution.industry_type.map((industry) => (
      <div>
        <span
          className='flex rounded-full bg-gray-100 px-[10px] py-[6px] text-xs text-black'
        >
          <img className='px-1' src={industryIcon} alt='industry-icon' />
          {industry}
        </span>
      </div>
  ))

  const CompanySizes = solution.size.map((size, index) => (
    <div >
      <span
        className='flex rounded-full bg-gray-100 px-[10px] py-[6px] text-xs text-black'
      >
        <img className='px-1' src={companySizeIcon} alt='industry-icon' />
        {reverseCompanySizeMap[size]}
      </span>
    </div>
))

  const { data, setData } = useForm({
    size: solution.size,
    industry_type: solution.industry_type,
    website: solution.website,
    description: solution.description,
    logo: logo,
    hero_image: solution.hero_image,
  })

  const handleFileChange = async (file, fieldName, maxSize, previewUrlSetter) => {
    const selectedFile = file

    if (selectedFile.size > maxSize) {
      toast(
        <ErrorMessage message={`File size is too large. Maximum file size allowed is ${maxSize / (1024 * 1024)}MB.`} />,
        defaultToastOptions('red-200')
      )
      return
    }

    const formData = new FormData()
    formData.append(`solution[${fieldName}]`, selectedFile)

    try {
      const response = await putRequest(`/solutions/${solution.id}`, formData, token)

      if (response.status === 200) {
        setData(response.data)
        toast(
          <SuccessMessage />,
          defaultToastOptions('teal-tint-2')
        )
        previewUrlSetter(URL.createObjectURL(selectedFile))
        setOpenModal(false)
      }
    } catch (error) {
      toast(
        <ErrorMessage />,
        defaultToastOptions('red-200')
      )
    }
  }

  const handleHeroImageChange = async (event) => {
    setCropImage(URL.createObjectURL(event.target.files[0]))
    setOpenModal(true)
    setTimeout(() => {
      event.target.value = ''
    }, 0)
  }

  const handleMobileHeroImageChange = async (event) => {
    setCropImage(URL.createObjectURL(event.target.files[0]))
    setOpenModal(true)
    setTimeout(() => {
      event.target.value = ''
    }, 0)
  }

  const isEmptyPromotionSection = () => {
    return (
      promotions?.length === 0 &&
      !showPermotionForm &&
      (current_user?.id === solutionOwner?.id || isRmcAdmin) &&
      !solution.hide_promotion_section &&
      current_user
    )
  }

  return (
    <Layout 
      noPadding={true}
      type='menu'
      hideNav={hideNav}
      hideDropdown={hideDropdown}
      setHideDropdown={setHideDropdown}
    >
      <PageTitle title={`Learn About ${solution?.name} on the Talivity Marketplace`} />
      <Meta
        title={`Learn About ${solution?.name} on the Talivity Marketplace`}
        description={`Connect with the top talent acquisition tech tools like ${solution?.name} on the Talivity Marketplace, formerly RecruitmentMarketing.com.`}
      />
      <ToastContainer
        closeButton={<img src={closeIcon} className='size-6' alt='close' />}
      />
        <HeroImageSection
          solution={solution}
          is_claimed={is_claimed}
          current_user={current_user}
          solutionOwner={solutionOwner}
          heroPreviewUrl={heroPreviewUrl}
          mobileHeroPreviewUrl={mobileHeroPreviewUrl}
          heroImageURL={heroImageURL}
          mobileHeroImageURL={mobileHeroImageURL}
          handleHeroImageChange={handleHeroImageChange}
          handleMobileHeroImageChange={handleMobileHeroImageChange}
          isRmcAdmin={isRmcAdmin}
          isMobile={isMobile}
          selectedInput = {selectedInput}
          setSelectedInput={setSelectedInput}
        />
      <div className='px-4 py-3 md:px-10 xl:w-[1360px] xl:self-center xl:px-20 xl:py-[20px]'>
        <Breadcrumbs breadcrumbs={ primary_category ? primaryCategoryBreadCrumbs : breadcrumbs} solution={solution} />
      </div>

      <ProfileHeader
        company={company}
        solution={solution}
        is_claimed={is_claimed}
        current_user={current_user}
        solutionOwner={solutionOwner}
        logo_url={logo_url}
        handleSubmitClaim={handleSubmitClaim}
        handleContactUs={handleContactUs}
        documents={documents}
        screenshots={screenshots}
        featuresLength={additional_features.length + categories_master_features.some((cm) => cm.selected_master_features.length)}
        videos={videos}
        isRmcAdmin={isRmcAdmin}
        isSolutionOwner={isSolutionOwner}
        customers={customers}
        integrations={integrations}
        promotionsLength={promotions.length}
        propositionsLength={propositions.length}
        customSolutionCta={customSolutionCta}
        setHideNav={setHideNav}
        setHideDropdown={setHideDropdown}
        solutionsToCompare={solutionsToCompare}
        setSolutionsToCompare={setSolutionsToCompare}
      />

      {/* Solutions Overview Container*/}
        {/* Similar Solutions Container */}
      {(!solution.hide_similar && similar_solutions.length > 0) && (
        <>
          <div className='bg-white px-4 py-10 md:p-10 xl:w-[1360px] xl:self-center xl:px-20 xl:py-[60px]'>
            <div>
              <div className='flex flex-col'>
                <div className='mb-2 flex items-center gap-2 pl-2 text-lg font-bold md:text-xl'>
                  <FaShuffle className='text-dark-blue' />
                  <h3 className='text-black'>
                    Similar Solutions
                  </h3>
                </div>
                <div className='flex flex-wrap'>
                  {similar_solutions?.map((solution, index) => (
                    <div
                      key={solution.id}
                      className={clsx(
                        'w-full p-2 sm:w-1/2 lg:w-1/3',
                        { 'hidden lg:block': index >= 4 }
                      )}
                    >
                      <div className='block mb-4 sm:hidden'>
                        {index == 3 && <PromoFastTrack />}
                      </div>
                      <SimilarSolutionCard
                        solution={solution}
                        solutionsToCompare={solutionsToCompare}
                        setSolutionsToCompare={setSolutionsToCompare}
                      />
                    </div>
                  ))}
                  <div className='hidden sm:block p-2 lg:p-1 w-full lg:w-2/3'>
                    <PromoFastTrack />
                  </div>
                </div>
                <Link href={getCategoryUrl(primary_category, solution)}>
                  <p className='flex justify-center pt-4 text-md font-semibold tracking-tight text-dark-blue sm:justify-end sm:pr-2'>
                    View All Similar Solutions &gt;&gt;
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div className='flex w-full justify-center'>
            <img src={pocketshadow} alt='shadow' />
          </div>
        </>
      )}

      <div className='bg-white px-4 py-10 md:p-10 xl:w-[1360px] xl:self-center xl:px-20 xl:py-[60px]'>
        {/* Solution Details */}
        <SolutionDetails 
          solution={solution}
          currentUser={current_user}
          socialLinks={social_links}
          solutionOwner={solutionOwner}
          aboutMedia = {about_media}
          isRmcAdmin = {isRmcAdmin}
        />

        {/*Solution Promotion Section*/}
        <div className='mt-14' id='promotion'>
          {!solution.hide_promotion_section && (
            <>
              <div className='flex gap-8 align-baseline'>
                {(promotions.length !== 0 || isSolutionOwner || isRmcAdmin) && 
                  <h2 className='mb-5 text-lg font-bold leading-[30px] text-black md:text-xl md:leading-[35px]'>
                    Promotions
                  </h2>
                }
                {(isSolutionOwner || isRmcAdmin) && (
                  <div className='ml-auto'>
                    <Button
                      size='md'
                      variant='tertiary'
                      className='flex size-9 items-center justify-center !rounded-full p-0'
                      onClick={handlePermotionEditClick}
                      style={{ padding: 0 }}
                    >
                      <img src={editIcon} alt={`Edit ${solution.name}`} />
                    </Button>
                  </div>
                )}
              </div>
              {!showPermotionForm && promotions.length !== 0 && (
                <div className='flex items-start gap-[24px] self-stretch max-md-sm:block'>
                  {promotions?.slice(0, 3).map((promotion, index) => (
                    <PromotionCard key={index} promotions={[promotion]} />
                  ))}
                </div>
              )}
            </>
          )}
          {isEmptyPromotionSection() && (
            <EmptySection
              heading='No Promotions Yet'
              paragraph='Click the pencil icon to add a promotion to your profile.'
            />
          )}
          {showPermotionForm && (
            <PromotionForm solution={solution} promotions={[promotions]} />
          )}
        </div>

        {/*Solution Pricing Section*/}
        {(!solution.hide_pricing_section || current_user) && (
          <PricingDetails
            solution={solution}
            currentUser={current_user}
            isClaimed={is_claimed}
            isSolutionOwner={isSolutionOwner}
            isRmcAdmin={isRmcAdmin}
            solutionOwner={solutionOwner}
            handleRequestAQuote={handleRequestAQuote}
          />
        )}

        {/* Product Information Section*/}
        <div className='product_information'>
          {solution.paid_solution &&
          (propositions.length > 0 || isSolutionOwner || isRmcAdmin) ? (
            <Propositions
              solutionPropositions={propositions}
              isSolutionOwner={isSolutionOwner}
              solutionSlug={solution.slug}
              isRmcAdmin={isRmcAdmin}
            />
          ) : (
            (isRmcAdmin || solutionOwner) && (
              ((current_user && current_user?.id === solutionOwner?.id) || isRmcAdmin) && (
                <div className='mt-20'>
                  <p className='mb-5 text-xl font-bold'>Product Information</p>
                  <EmptySection
                    heading='No Product Information Yet'
                    paragraph='Update to a premium profile to add product information to your profile'
                    showUpgradeButton={true}
                  />
                </div>
              )
            )      
          )}
        </div>   

        {/* Integrations Section */}
        <div className='integrations'>
          {(isSolutionOwner || isRmcAdmin || integrations.length > 0) && (
            <Integration
              integrations={integrations}
              solutionSlug={solution.slug}
              isSolutionOwner={isSolutionOwner}
              isRmcAdmin={isRmcAdmin}
            />
          )}
        </div>
        {/* Features Section */}
        {(isSolutionOwner ||
          isRmcAdmin ||
          additional_features.length > 0 ||
          categories_master_features.some(
            (cm) => cm.selected_master_features.length > 0
          )) && (
          <div id='features'>
            <SolutionFeatures
              features={additional_features}
              categories_master_features={categories_master_features}
              solution={solution}
              isSolutionOwner={isSolutionOwner}
              isRmcAdmin={isRmcAdmin}
            />
          </div>
        )}
        {/* Our Customers Section */}
        <div id='our_customers'>
          <OurCustomersSection
            customers={customers}
            solution={solution}
            current_user={current_user}
            solutionOwner={solutionOwner}
            isRmcAdmin={isRmcAdmin}
          />
        </div>

        {/* Testimonials Section */}
        <div id='testimonials'>
          {solution.paid_solution? (
            <TestimonialsSection
              isSolutionOwner={isSolutionOwner}
              solution={solution}
              current_user={current_user}
              testimonials={testimonials}
              isRmcAdmin={isRmcAdmin}
            />
          ) : (
            (isRmcAdmin || isSolutionOwner) && (
              <div className='mt-20'>
              <p className='mb-5 text-xl font-bold'>Customer Testimonials</p>
              <EmptySection
                heading='No Testimonials Yet'
                paragraph='Update to a premium profile to add testimonials to your profile'
                showUpgradeButton = {true}
              />
            </div>
            )
          )}
        </div>

        {/* Official Media Section */}
        <div id='official_media'>
          {
            (isSolutionOwner || isRmcAdmin ||
            (!documents?.every(obj => obj.id === null) || !screenshots?.every(obj => obj.id === null) || !videos?.every(obj => obj.id === null)))
            &&
            <OfficialMediaSection 
              solution={solution}
              documents={documents}
              current_user={current_user}
              is_claimed={is_claimed}
              solutionOwner={solutionOwner}
              videos={videos}
              screenshots={screenshots}
              isRmcAdmin={isRmcAdmin}
            />
          }
        </div>

        {/* Awards Section */}
        {!!rmc_awards?.length && <AwardsSection rmcAwards={rmc_awards} />}
        {/* RMC News & Media */}
        {rmc_news_and_media.length > 0 && (
          <RmcNewsAndMedia {...rmc_news_and_media} />
        )}
        {showFaq ||
        faqs.filter((faq) => faq.is_active && String(faq.answer).trim())
          .length !== 0 ? (
          <FaqSection
            faqs={faqs}
            isClaimed={is_claimed}
            isSolutionOwner={isSolutionOwner}
            solution={solution}
            isRmcAdmin={isRmcAdmin}
          />
        ) : null}
      </div>

      {/* Contact Vendor Section for buyer View*/}
      {role === 'vendor' && <StartGeneratingQualityLeadsCTA />}
      <UnauthorizedClaimModal
        openUnauthorizedFormModal={openUnauthorizedFormModal}
        setOpenUnauthorizedFormModal={setOpenUnauthorizedFormModal}
        solutionID={solution.id}
        solutionName={solution.name}
        paid_solution={solution.paid_solution}
        isClaim={isClaim}
        solutionOwner={solutionOwner}
        solutionClaimed={is_claimed}
        redirectURL={solution.website || company?.website}
        setStatus={setStatus}
        showRequestAQuote={showRequestAQuote}
        setShowRequestAQuote={setShowRequestAQuote}
        contactUsBtn={contactUsBtn}
        setContactUsBtn={setContactUsBtn}
        logoUrl={logo_url}
        company={company}
      />
      <Modal
        isOpen={modals.IndustriesModal}
        onClose={(event) => handleModals(event, 'IndustriesModal', false)}
        closeStyle='top-3 right-5'
        className='mx-4 max-h-[500px] overflow-y-scroll rounded-xl bg-white px-4 py-5'
      >
        <div className='flex-wrap gap-2 md:mt-5 md:flex md:max-w-[339px] xl:max-w-[551px]'>
          {industries}
        </div>
      </Modal>
      <Modal
        isOpen={modals.CompanySizeModal}
        onClose={(event) => handleModals(event, 'CompanySizeModal', false)}
        closeStyle='top-3 right-5'
        className='mx-4 max-h-[500px] overflow-y-scroll rounded-xl bg-white px-4 py-5'
      >
        <div className='flex-wrap gap-2 md:mt-5 md:flex md:max-w-[339px] xl:max-w-[551px]'>
          {CompanySizes}
        </div>
      </Modal>
      <Modal
        isOpen={openClaimModal}
        onClose={() => setOpenClaimModal(false)}
        closeStyle='top-5 right-4'
        className='mx-4 w-full rounded-xl  bg-white px-4 py-5 md:mt-0 md:w-[440px]'
      >
        <div>
          <h2 className='mb-4 text-2xl font-bold'>Claim Solution?</h2>
          <p className='mb-6 text-sm'>
            Recruitment Marketing will verify your claim for this solution. For
            faster verification, we recommend using a business email.
          </p>
          <div>
            <label className='mb-[2px] text-[11px] font-semibold uppercase'>
              Name:
            </label>
            <p className='text-lg'>
              {current_user?.first_name} {current_user?.last_name}
            </p>
          </div>
          <div className='mb-[36px]'>
            <label className='mb-[2px] text-[11px] font-semibold uppercase'>
              Email:
            </label>
            <p className='text-lg'>{current_user?.email}</p>
          </div>
        </div>
        <div className='flex flex-col items-center gap-4 md:flex-row'>
          <Button
            type='button'
            size='md'
            variant='primary'
            className='w-full'
            onClick={() => handleClaim()}
          >
            Submit Claim
          </Button>
          <Link
            href={`/users/edit`}
            className='w-full'
            data={{ editForm: true }}
          >
            <Button
              type='button'
              size='md'
              variant='secondary'
              className='w-full'
            >
              Edit My Account
            </Button>
          </Link>
        </div>
      </Modal>
      <ImagePreviewModal 
        openModal={openModal}
        setOpenModal={setOpenModal}
        src={cropImage}
        handleFileChange={handleFileChange}
        previewUrlSetter={setHeroPreviewUrl}
        mobilePreviewUrlSetter={setMobileHeroPreviewUrl}
        MAX_HERO_IMAGE_SIZE={MAX_HERO_IMAGE_SIZE}
        selectedInput={selectedInput}
      />
      <LeadGenerationModal
        openModal={openLeadGenModal}
        setOpenModal={setOpenLeadGenModal}
        solutionId={solution.id}
        sourceBtn={leadSourceBtn}
        formType={'leadSubmission'}
        solutionName={solution.name}
        currentUser={current_user}
      />
      {/* Solution Comparison Tab */}
      {solutionsToCompare?.length > 0 && (
        <div className='sticky bottom-0 z-[10] bg-[#ECEDF1] shadow-sticky-bar'>
          <CompareSolutionTab
            solutionsToCompare={solutionsToCompare}
            setSolutionsToCompare={setSolutionsToCompare}
          />
        </div>
      )}
    </Layout>
  )
}

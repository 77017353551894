export const SolutionStarRatingVariants = {
  primary: {
    ratingCount: 'text-[0.65rem] leading-[12px] text-black sm:text-sm',
    rating: 'text-[0.65rem] font-bold text-dark-gray sm:text-sm',
    ratingStar: 'flex flex-wrap items-center gap-1'
  },
  secondary: {
    ratingCount: 'text-[10.8px] text-medium-gray mt-0.5',
    rating: 'mt-0.5 text-[10.8px] font-semibold text-dark-blue',
    ratingStar: 'flex flex-wrap items-center gap-2'
  }
}

export const SolutionCompletenessGradient = (filledPercentage: number) => ({
  container: 'relative h-3 w-full bg-gray-300',
  gradientBackground: {
    background: `linear-gradient(to right, #0A165C, #00C8BB ${filledPercentage}%, #d1d1d1 ${filledPercentage}%)`
  }
})

export const MUIBlueStarRating = {
  '& .MuiRating-iconFilled': {
    color: '#031931',
  },
  '& .MuiRating-iconHover': {
    color: '#031931',
  },
  '& .MuiRating-iconEmpty': {
    color: '#031931',
  }}

  export const SubmitButtonClasses = (isFormValid: boolean) => `
  !inline-block 
  !rounded-xl 
  !transition 
  !duration-300 
  !focus:outline-none 
  ${isFormValid ? "!bg-talivity-teal !text-black" : "!bg-light-gray !text-disabled !opacity-50"} 
  !font-bold 
  !px-8 
  !py-[14px] 
  !text-md 
  !min-w-[164px]  
  !cursor-pointer
`
import React, { useEffect, useState } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material'
import awaitingLogo from 'app/assets/images/awaitingLogo.svg'
import logo from 'app/assets/images/logo.svg'
import { format } from 'date-fns'
import { IoLocationSharp } from 'react-icons/io5'

import Button from '@/components/atoms/buttons/Button'
import SolutionLeadsMessage from '@/components/atoms/solution/SolutionLeadsMessage'
import { getRequest } from '@/utils/axiosRequests'
import { dummyLeads, fetchingLeadsHeading, fetchingLeadsSubheading, MLP_PROMO_LINK, noLeadsHeading, noLeadsSubheading } from '@/utils/constants'

export default function SolutionProfileVisits({ solutionSlug, isSolutionPaid }) {
  const [leads, setLeads] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchProfileVisits = async () => {
      setLoading(true)
      try {
        if (isSolutionPaid) {
          const response = await getRequest(`/leadfeeder_data/${solutionSlug}`)
          setLeads(response.data || [])
        }
        else {
          setLeads(dummyLeads)
        }
      } catch (error) {
        console.error('Error fetching profile visits:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchProfileVisits()
  }, [])

  const handleChangePage = (_, newPage) => setPage(newPage)
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <div className='my-6'>
      {loading ? (
        <SolutionLeadsMessage logo={awaitingLogo} heading={fetchingLeadsHeading} subheading={fetchingLeadsSubheading} />
      ) : (isSolutionPaid && leads.length === 0) ? (
        <SolutionLeadsMessage logo={logo} heading={noLeadsHeading} subheading={noLeadsSubheading} />
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  '& .MuiTableCell-root': { fontWeight: 600, fontSize: 14, color: '#2E343A' },
                }}
              >
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  <TableCell>No. of Employees</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Industry</TableCell>
                  <TableCell>Most Recent Visit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {leads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((lead, index) => (
                  <TableRow
                    key={index}
                    sx={{ '& .MuiTableCell-root': { fontSize: 15, color: '#2E343A' } }}
                  >
                    <TableCell
                      sx={{
                        filter: !isSolutionPaid ? 'blur(5px)' : 'none',
                        userSelect: !isSolutionPaid ? 'none' : 'text'
                      }}
                    >
                      {isSolutionPaid ? lead.company_name : 'Not Available'}
                    </TableCell>
                    <TableCell>
                      {isSolutionPaid ? (
                        lead.employees_range.min === 1 && lead.employees_range.max === 1
                          ? '1'
                          : lead.employees_range.min && lead.employees_range.max
                            ? `${lead.employees_range.min} - ${lead.employees_range.max}`
                            : 'Unknown'
                      ) : (
                        lead.employee_count
                      )}
                    </TableCell>

                    <TableCell>
                      <div className='flex items-center gap-1'>
                        <IoLocationSharp className='size-5 text-[rgba(46,52,58,0.54)]' />
                        {lead.region}, {lead.country_code}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        filter: !isSolutionPaid ? 'blur(5px)' : 'none',
                        userSelect: !isSolutionPaid ? 'none' : 'text'
                      }}
                    >
                      <p className='inline-block rounded px-2 py-1 text-[13px]'
                        style={{
                          background: 'rgba(46, 52, 58, 0.08)'
                        }}
                      >
                        {lead.industry !== 'N/A' ? lead.industry : 'Unknown'}
                      </p>
                    </TableCell>
                    <TableCell>
                      {format(new Date(lead.last_visit_date), 'MM-dd-yyyy')}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isSolutionPaid &&
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={leads.length}
              component='div'
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          }
        </>
      )}
      {(!isSolutionPaid && !loading) &&
        <div className='mt-6 flex flex-col gap-2 text-[15px]'>
          <p>Want to see who's been viewing your profile?</p>
          <Button
            variant='secondary'
            className='mt-1 max-w-[246px] px-4 py-3 text-md font-medium'
            onClick={() => window.location.href = MLP_PROMO_LINK}>
            Upgrade Account
          </Button>
        </div>
      }
    </div>
  )
}

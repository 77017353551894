import WaitlistImage from "app/assets/images/Networking-Laptop.webp"
import Button from "@/components/atoms/buttons/Button"
import { memo } from "react";

const Waitlist = () => {
  return (
    <div className="flex flex-col-reverse lg:flex-row mt-[64px] items-center justify-between xl:gap-[80px]">
      <div className="flex flex-col sm:w-[55%] lg:items-start justify-start ml-[20px] md:ml-[120px] text-outer-space pr-[50px] pt-[60px] gap-[24px]">
      <p className="text-[#031931] text-[34px] font-extrabold leading-[45px]">
        Shape the Future of Work: Join the Talivity Network
        </p>
        <p className="text-[16px] font-bold">
        Talivity is where innovative talent leaders explore cutting-edge technology. The Talivity Network is your gateway to inspiration for your future talent strategies, fueled by collective expertise.
        <br/><br/>
        Connect with visionary HR leaders at our free events, including our flagship event Hire Innovations, to access exclusive insights, market intelligence, and be at the forefront of HR Tech innovation. Your next breakthrough idea is just a conversation away.
        </p>
        <div>
          <a href="https://hire-innovations.com/" target="_blank">
            <Button
              variant="teal"
              className="mx-auto mt-[16px] flex h-[70px] w-[330px] sm:w-[350px] text-[18px] items-center justify-center !text-[#031931] py-[4px] sm:text-[1rem] xl:text-[18px] font-medium bg-talivity-teal rounded-[8px]"
            >
              Join the Next Hire Innovations Event
            </Button>
          </a>
        </div>

      </div>
      <div className="lg:w-[40%] md:w-[50%] w-[85%]">
        <img
          src={WaitlistImage}
          alt="Waitlist image"
          loading="lazy"
          rel="nofollow"
          className="w-full h-full"
        />
      </div>
    </div>
  );
};

export default memo(Waitlist)
import { memo, useMemo } from 'react'
import clsx from 'clsx'
import { FaStarHalfAlt as HalfStar, FaStar as Star } from 'react-icons/fa'

import { SolutionStarRatingVariants } from '@/utils/constants/styles'

type SolutionStarRatingProps = {
  rating: number
  ratingCount: number
  showDetails?: boolean
  variant?: 'primary' | 'secondary'
}

export function SolutionStarRating({
  rating,
  ratingCount,
  showDetails = true,
  variant = 'primary'
}: SolutionStarRatingProps) {
  const roundedRating = useMemo(() => (Math.round(rating * 10) / 10).toFixed(1), [rating])
  const roundedRatingNumber = useMemo(() => Number(roundedRating), [roundedRating])
  const ratingsCount = ratingCount || 0
  const variantClasses = SolutionStarRatingVariants[variant]

  const RatingsDetails = () => {
    return (
      showDetails &&
      (
        ratingsCount === 0 ?
          <p className={variantClasses.ratingCount}>0 ratings</p> :
          <>
            <div className={variantClasses.rating}>{roundedRating}</div>
            <div className={variantClasses.ratingCount}>
              ({ratingsCount} ratings)
            </div>
          </>
      )
    )
  }

  const RatingStars = () => {
    const validateHalfStar = (index: number) => {
      return Math.round(roundedRatingNumber) !==
            Math.floor(roundedRatingNumber) &&
            index + 1 === Math.round(roundedRatingNumber)
    }

    return (
      ratingsCount > 0 &&
      <div className='flex items-center gap-[2px]'>
        {Array.from({ length: 5 }).map((_, index) => {
          const hasFullStar = index + 1 <= roundedRatingNumber
          const hasHalfStar = validateHalfStar(index)

          return (
            <div key={index}>
              {!hasFullStar && hasHalfStar ? (
                <HalfStar className='text-[0.65rem] text-dark-blue sm:text-sm' />
              ) : (
                <Star
                  className={clsx(
                    `stroke-dark-blue stroke-[10px] text-[0.65rem] sm:text-sm
                    ${hasFullStar ? 'text-dark-blue' : 'text-white'}`
                  )}
                />
              )}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className={variantClasses.ratingStar}>
      <RatingStars />
      <RatingsDetails />
    </div>
  )
}

export default memo(SolutionStarRating)
import { FASTTRACK_PROMO_URL } from '@/utils/constants'
import HybridLink from '../widgets/HybridLink'
import fastTrackImage from '../../../../assets/images/fast-track-promo.png'


const PromoFastTrack = () => {
  return (
    <HybridLink href={FASTTRACK_PROMO_URL}>
      <div
        className='flex flex-col sm:flex-row h-[350px] sm:max-h-[120px] rounded-lg p-6 shadow-card justify-between items-center'
        style={{
          background: `linear-gradient(0deg, rgba(3, 25, 49, 0.64) 0%, rgba(3, 25, 49, 0.64) 100%), linear-gradient(0deg, rgba(3, 25, 49, 0.64) 0%, rgba(3, 25, 49, 0.64) 100%), url(${fastTrackImage})`,
          backgroundBlendMode: 'normal, multiply, normal',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className='w-[286px] h-[246px] sm:h-auto sm:w-auto flex flex-col justify-between text-center sm:text-start sm:gap-2'>
          <h2 className='text-white text-2xl font-semibold leading-8'>Build Your Dream HR Tech Stack</h2>
          <p className='text-gray-400 leading-5 text-sm px-0.5 sm:w-[90%] md:w-[82%]'>
            In less than 10 minutes, answer a few questions and receive personalized HR tech stack recommendations from across the market.
          </p>
        </div>
        <button
          className='h-[40px] min-w-[163px] max-w-[163px] bg-pink-tint-3 text-white font-medium text-sm leading-6 tracking-[0.4px] flex justify-center items-center rounded-xl whitespace-nowrap'
        >
          Get Matched Now
        </button>
      </div>
    </HybridLink>
  )
}

export default PromoFastTrack

import { useState, useEffect, ReactNode } from 'react';
import { useMobileDetect } from '../../../utils/detectMobile'

import { Link } from '@inertiajs/react';
import editIcon from '../../../../assets/images/edit-pencil-no-bg.svg';
import { ReactComponent as StarIcon } from '../../../../assets/images/star.svg';
import StarBlueNoFill from '../../../../assets/images/Star-unfilled.svg';
import StarBlueFilled from '../../../../assets/images/Star-filled.svg';
import { ReactComponent as HalfStarIcon } from '../../../../assets/images/half-filled-star.svg';
import HalfStarBlue from '../../../../assets/images/Star-half-filled.svg';
import Button from '../../atoms/buttons/Button';
import solutionLogoPlaceholder from '../../../../assets/images/solution-logo-placeholder.svg';
import { SolutionStarRating } from 'app/javascript/components/molecules/solution/SolutionStarRating'

import SolutionCategories from './SolutionCategories';

type SolutionCardProps = {
  solution: schema.Solution;
  className?: string;
  hideEditButton?: boolean;
  hideDescription?: boolean;
  hideTags?: boolean;
  entryPoint?: string;
  cardShadow?: boolean;
  superFeaturedSection?: boolean;
  solutionsToCompare?: schema.Solution[];
  setSolutionsToCompare?: React.Dispatch<
    React.SetStateAction<schema.Solution[]>
  >;
};

export function SolutionTag({
  children,
  classes,
}: {
  children: ReactNode;
  classes: string;
}) {
  return (
    <div className={classes}>
      <p className='truncate'>{children}</p>
    </div>
  );
}

export function SolutionCard({
  solution,
  className,
  hideEditButton,
  hideDescription = false,
  hideTags = false,
  entryPoint,
  cardShadow = true,
  superFeaturedSection = false,
  solutionsToCompare,
  setSolutionsToCompare,
}: SolutionCardProps) {
  const [isChecked, setIsChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMobileDetect()

  useEffect(() => {
    const solutionNames = solutionsToCompare?.map((s) => {
      return s.name;
    });
    if (solutionNames?.includes(solution.name)) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [solutionsToCompare]);

  const handleOnChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleCompareSolution = (e) => {
    e.currentTarget.blur();
    let updatedSolutionsToCompare = [...solutionsToCompare];
    if (e.target.checked) {
      setIsChecked(true);
      if (!updatedSolutionsToCompare.includes(solution))
        updatedSolutionsToCompare.push(solution);
    } else {
      updatedSolutionsToCompare = updatedSolutionsToCompare.filter((s) => {
        if (s.name === solution.name) return false;
        return true;
      });
    }
    setSolutionsToCompare(updatedSolutionsToCompare);
  };

  const solutionTags = solution?.categories_names?.sort() || [];
  const categorySlugs = solution?.categories_slugs?.sort() || [];

  return (
    <Link
      href={`/solutions/${solution.slug}`}
      className='snap-center'
    >
      <div
        className={`relative text-clip rounded-xl ${
          cardShadow && 'shadow-card'
        } ${className} bg-white hover:outline hover:outline-1 hover:outline-medium-gray`}
        id='solution-card-container'
      >
        {solution?.super_featured && superFeaturedSection && (
          <div
            className={
              'absolute left-[-4px] top-[-5px] w-[109px] rounded-t-lg rounded-r-lg bg-dark-blue py-1 text-center text-sm font-bold uppercase tracking-[1px] text-white before:absolute before:top-[100%] before:left-[0px] before:z-[10] before:border-[3px] before:border-r-[5px] before:border-t-[5px] before:border-r-dark-blue before:border-t-dark-blue'
            }
          >
            Featured
          </div>
        )}
        <div
          className={clsx(
            'flex h-full overflow-hidden p-4',
            superFeaturedSection ? 'mt-4' : 'mt-0'
          )}
        >
          <div className='flex max-h-[72px] w-full max-w-[72px]'>
            <img
              className='min-h-[72px] min-w-[72px] rounded-full'
              src={solution?.logo_url || solutionLogoPlaceholder}
              alt={solution?.name}
              rel='nofollow'
            />
          </div>
          <div className='ml-4 flex h-full w-full flex-col gap-3 lg:gap-4'>
            <div>
              <div className='flex flex-col gap-3'>
                <div className='flex flex-col gap-2'>
                  <div className='flex items-center justify-between mt-1'>
                    <h2
                      className={`break-all mb-0 text-left font-Manrope font-bold uppercase text-black line-clamp-3`}
                      style={{ letterSpacing: '1px' }}
                    >
                      {solution.name}
                    </h2>
                    {solution?.is_featured && !superFeaturedSection && (
                      <p
                        className={`rounded-md bg-dark-blue px-[6px] py-[2px] text-[13px] font-bold leading-5 text-white ${
                          hideEditButton ? '' : 'mr-11'
                        }`}
                      >
                        Featured
                      </p>
                    )}
                  </div>
                  {!superFeaturedSection && solution?.ratings_count >= 0 && (
                    <SolutionStarRating rating={solution.rating} ratingCount={solution.ratings_count} />
                  )}
                </div>
                {!solution.super_featured || !superFeaturedSection ? (
                  <p
                    className={`hidden break-words pr-[44px] text-[13px] font-medium md:block md:line-clamp-3`}
                  >
                    {!hideDescription && solution.description}
                  </p>
                ) : // Top featured categories
                <SolutionCategories
                  isExpanded={isExpanded}
                  solution={solution}
                  isMinify={false}
                  categoriesToShow={1}
                  solutionTags={solutionTags}
                  categorySlugs={categorySlugs}
                  setIsExpanded={setIsExpanded}
                />
                }
              </div>
            </div>
          </div>
        </div>
        {solutionsToCompare && <hr />}
        {!hideTags && solutionsToCompare && (
          <div className='mt-4 flex justify-between overflow-hidden px-4 pb-4'>
            <div className='mr-2 flex items-center gap-2'>
              <input
                type='checkbox'
                onChange={(e) => handleOnChange(e)}
                className='border-gray h-5 w-5 cursor-pointer rounded-md checked:bg-dark-blue focus:ring-0 focus:ring-offset-0 sm:h-4 sm:w-4'
                onClick={(e) => {
                  e.stopPropagation();
                  handleCompareSolution(e);
                }}
                checked={isChecked}
                disabled={!(solutionsToCompare?.length < 4) && !isChecked}
              />
              <label className='text-xs font-semibold'>Compare</label>
            </div>
            <SolutionCategories
              solution={solution}
              isExpanded={isExpanded}
              isMinify={isMobile}
              categoriesToShow={2}
              solutionTags={solutionTags}
              categorySlugs={categorySlugs}
              setIsExpanded={setIsExpanded}
            />
          </div>
        )}
        {!hideEditButton && (
          <Link href={`/solutions/${solution.slug}`}>
            <Button
              size='md'
              variant='tertiary'
              className='absolute top-4 right-4 flex h-9 w-9 items-center justify-center rounded-full p-0'
              style={{ padding: 0 }}
            >
              <img src={editIcon} alt={`Edit ${solution.name}`} />
            </Button>
          </Link>
        )}
      </div>
    </Link>
  );
}

export function CompactSolutionCard({
  solution,
  className,
  entryPoint,
}: SolutionCardProps) {
  return (
    <Link href={`/solutions/${solution.slug}`}>
      <div
        className={`relative mr-1 flex h-[172px] min-h-[172px] w-[172px] flex-col items-center justify-around text-clip rounded-xl py-5 px-4 shadow-card ${className} bg-white hover:outline hover:outline-1 hover:outline-medium-gray`}
      >
        <img
          src={solution.logo_url || solutionLogoPlaceholder}
          alt={solution.name}
          className='h-[72px] w-[72px] rounded-full'
        />
        <div>
          <p className='text-center text-sm leading-[21px]'>{solution.name}</p>
          {solution?.is_featured && (
            <p className='text-[13px] font-bold text-dark-blue'>Featured</p>
          )}
        </div>
      </div>
    </Link>
  );
}

import { useRef, useState, memo } from 'react'
import { FaWandMagicSparkles } from 'react-icons/fa6'
import { CategoryFeatures } from './CategoryFeatures'
import { MdOutlineCreate } from 'react-icons/md'
import Button from '../buttons/Button'
import axios from 'axios'
import { useToken } from '@/components/atoms/forms/Form'
import { toast } from 'react-toastify'
import SuccessMessage from '@/components/atoms/Toast/SuccessMessage'
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage'
import { defaultToastOptions } from '@/utils/commonToast'
import { EditCategoryFeatures } from './EditCategoryFeatures'
import { TIMEOUT_INTERVAL } from '@/utils/constants'
import { postRequestWithoutFormData } from '@/utils/axiosRequests'
import { AdditionalFeature } from '@/components/molecules/additionalFeatureSection/AdditionalFeature'
import { router, useForm } from "@inertiajs/react"
import EmptySection from '@/components/atoms/widgets/EmptySection'
import {
  NUM_OF_LIST_ELEMENTS_LARGE_SCREEN,
  NUM_OF_LIST_ELEMENTS_SMALL_SCREEN,
  WIDTH_SMALL_SCREEN,
} from "@/components/atoms/constants/screenConstant"

const SolutionFeatures = ({
  features,
  categories_master_features,
  solution,
  isSolutionOwner,
  isRmcAdmin,
}) => {
  const [editView, setEditView] = useState(false)
  const token = useToken()
  const showEmptySection = (!editView && features.length === 0 &&  categories_master_features?.every(cm => cm.selected_master_features.length === 0) && (isRmcAdmin || isSolutionOwner))
  const [selectedFeatures, seSelectedFeatures] = useState({})
  const numOfListElements = useRef(
    window.innerWidth > WIDTH_SMALL_SCREEN
      ? NUM_OF_LIST_ELEMENTS_LARGE_SCREEN
      : NUM_OF_LIST_ELEMENTS_SMALL_SCREEN
  )
  const [additionalFeaturesToShow, setAdditionalFeaturesToShow] = useState(
    features.slice(0, numOfListElements.current)
  );

  const { data, setData } = useForm({
    feature_solutions_attributes: features.map((feature) => ({
      id: feature.id,
      feature_id: feature.feature_id,
      _destroy: false,
      feature_attributes: {
        id: feature.feature_id,
        name: feature.name,
        source: feature.source,
        _destroy: false,
      },
    })),
  })

  const closeEditView = () => {
    setEditView(false)
    setAdditionalFeaturesToShow(
      features.slice(0, numOfListElements.current)
    )
  }

  const handleSubmit = async () => {
    try {
      const features_data = {selected_features: selectedFeatures}
      const solutionUpdateURL = `/solutions/${solution.slug}`
      const featureUpdateURL = `/solutions/${solution.slug}/update_solution_features`
      const masterFeatureResponse = await postRequestWithoutFormData(solutionUpdateURL+`/set_selected_features`, features_data, token)
      const AdditionalFeatureresponse = await axios.put(
        featureUpdateURL,
        {
          solution: {
            feature_solutions_attributes: data.feature_solutions_attributes,
          },
        },
        {
          headers: {
            "X-CSRF-Token": token,
            Accept: "application/json",
          },
        }
      );
      if (masterFeatureResponse.status == 200 && AdditionalFeatureresponse.status == 200) {
        toast(
          <SuccessMessage
            message={'Your changes have been saved successfully!'}
          />,
          defaultToastOptions('teal-tint-2')
        )
        setTimeout(() => router.visit(solutionUpdateURL), TIMEOUT_INTERVAL)
      } else {
        toast(
          <ErrorMessage message={'Something went wrong! Please try again.'} />,
          defaultToastOptions('red-200')
        )
      }
    } catch (error) {
      toast(
        <ErrorMessage message={'Something went wrong! Please try again.'} />,
        defaultToastOptions('red-200')
      )
    }
  }

  return (
    <div className='mt-10'>
      <div className='flex gap-2 justify-between mb-5'>
        <div className='flex gap-2 items-center'>
          <FaWandMagicSparkles className='text-[30px]' />
          <h2 className='text-lg font-bold leading-[30px] text-black md:text-xl md:leading-[35px]'>
            Product Features
          </h2>
        </div>
        {(isSolutionOwner || isRmcAdmin) && !editView && (
          <MdOutlineCreate
            className='bg-light-gray p-2 rounded-full text-[35px] cursor-pointer'
            onClick={() => setEditView(true)}
            aria-label='Edit features'
          />
        )}
        {editView && (
          <div className='flex gap-2'>
            <Button
              variant='teal'
              className='flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3'
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
            <Button
              variant='gray'
              className='flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3'
              onClick={() => closeEditView()}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
      {showEmptySection && (
        <EmptySection heading='No Features Yet' paragraph='Click the pencil icon to add a feature to your profile.'/> 
        )}
      <div>
        {editView ? (
          <EditCategoryFeatures
            categories_master_features={categories_master_features}
            seSelectedFeatures={seSelectedFeatures}
          />
        ) : (
          <CategoryFeatures
            categories_master_features={categories_master_features}
          />
        )}
      </div>
      <AdditionalFeature
        additional_features={features}
        isSolutionOwner={isSolutionOwner}
        isRmcAdmin={isRmcAdmin}
        data={data}
        setData={setData}
        editView={editView}
        numOfListElements={numOfListElements}
        additionalFeaturesToShow={additionalFeaturesToShow}
        setAdditionalFeaturesToShow={setAdditionalFeaturesToShow}
      />
    </div>
  )
}

export default memo(SolutionFeatures)
